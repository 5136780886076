@import "./../../assets/scss/variables.scss";
@import "./../../industria-ui/scss/mixins/media-queries";

.bottom-bar {
  height: 50px;
  border-top: 1px solid #d5dceb;
  background: $ghost-white;
  color: $cadet-grey;
  font-size: 0.8125rem;
  @include respond-below(sm) {
    height: 80px;
    .row > div {
      flex-direction: column;
      justify-content: center;
    }
  }
  .privacy  {
    @include respond-below(sm) {
      text-align: center;
    }

    & .link-secondary {
      font-size: 0.8125rem;
      margin-right: 30px !important;
    
      @include respond-below(sm) {
        margin-right: 10px !important;
      }
    }
  }

  &.dark {
    background: $onyx-dark;
    border-top-color: $onyx-dark;
  }

  .copy {
    p {
      color: $cadet-grey;
      font-size: .8125rem;
      margin-bottom: 0;
    }
  }
}
