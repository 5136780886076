@import "./../../assets/scss/variables.scss";
@import "./../../industria-ui/scss/mixins/media-queries";

.cnf-path {
  text-transform: uppercase;
  font-size: 0.875rem;
  width: 100%;
  font-weight: 500;
  @include respond-below(sm) {
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .path {
    color: $cadet-grey;
    @include respond-below(sm) {
      line-height: 1;
    }
    &::after {
      content: "";
      width: 1px;
      height: 30px;
      background: #d5dceb;
      display: inline-block;
      vertical-align: middle;
      margin: 0px 20px;
      margin-top: -3px;
      @include respond-below(sm) {
        background: transparent;
        margin: 0;
      }
    }
    i {
      font-size: 22px;
      vertical-align: middle;
      &::before {
        padding-bottom: 3px;
        margin: 0 0 0 -3px;
      }
    }
  }
  a {
    color: $black-coral;
    &:hover {
      color: $red;
    }
  }
}
