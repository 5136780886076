@import "../../assets/scss/variables.scss";

.download-node-config {
  .download {
    box-shadow: 0 0 20px rgba(92, 98, 117, 0.1);
    padding: 40px 30px;
    .h-splitter {
      width: 100%;
      height: 1px;
      background-color: $ghost-white;
      margin: 20px 0 30px 0;
    }
    .h3 {
      font-weight: $fw-bold;
      margin-bottom: 35px;
    }
    p {
      margin-top: 40px;
      span {
        font-weight: $fw-bold;
      }
      a {
        color: $red;
      }
    }
    .btn-download {
      line-height: 0.875rem;
      width: 60%;
      i {
        color: $red;
        font-size: $fs-base;
      }
      .template-name {
        text-transform: uppercase;
        color: $red;
        font-size: $fs-sm;
        font-weight: $fw-bold;
        margin-left: 5px;
        font-family: $ff-sans-serif;
      }
      .size {
        color: $cadet-grey;
        font-size: $fs-sm;
        font-weight: $fw-normal;
        font-family: $ff-sans-serif;
        text-transform: none;
        text-transform: lowercase;
      }
    }
  }
  .info {
    font-size: $fs-base;
    color: $black-coral;
    div {
      i {
        color: $cadet-grey;
        font-size: 3.25rem;
      }
    }
    &__text {
      line-height: 1.75rem;
      a {
        color: $red;
      }
    }
  }

  .info-box {
    background-color: #EDF0F7;
    border-radius: .3125rem;
    padding: 20px;
    color: #5C6275;
    line-height: 1;
    cursor: pointer;

    i {
      color: inherit;
      font-size: 20px;
      margin-left: 10px;
    }

    &:hover {
      i {
        transition: color 0.2s ease-in-out;
        color: #EC1D24;
      }
    }
  }
}
