@import "./../../assets/scss/variables.scss";

.tooltip {
  margin-top: 16px;
  &-trigger {
    display: block;
    z-index: 55;
    background: transparent;
    width: 100%;
  }
  &-bubble {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    z-index: 10;
    background: $black-coral;
    padding: 15px 20px;
    border-radius: 5px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
    &::before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 6px solid $black-coral;
      position: absolute;
      bottom: -6px;
      right: 24px;
    }
    .tooltip-message {
      color: $white;
      a {
        color: $white;
        text-decoration: underline;
        pointer-events: all;
        cursor: pointer;
      }
    }
    &.shown {
      opacity: 1;
    }
  }
}
