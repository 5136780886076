@import "../../assets/scss/variables.scss";

.create-node-config {
  min-height: calc(100vh - 120px);
  margin-top: -37px;
  .h-splitter {
    width: 100%;
    height: 1px;
    background-color: $ghost-white;
    margin: 20px 0 30px 0;
  }
  .form {
    box-shadow: 0 0 20px rgba(92, 98, 117, 0.1);
    padding: 40px 30px;
    &__wrapper {
      .h3 {
        font-weight: $fw-bold;
        margin-bottom: 30px;
        line-height: 1.75rem;
      }
      .fields {
        &__header {
          text-transform: uppercase;
          color: $black-coral;
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 10px;
        }
        &__wrapper {
          margin: 30px 70px 40px;
          .info-box {
            font-size: 14px;
            color: $black-coral;
            background-color: $ghost-white;
            border-radius: 5px;
            padding: 20px 24px 20px 18px;
            line-height: 26px;
            margin-top: 30px;
            a {
              color: $red;
              background-image: none;
            }
            span {
              color: $onyx;
              font-weight: $fw-bold;
            }
            div:nth-of-type(2) {
              margin-top: 20px;
            }
          }
          .tooltip {
            margin-top: 30px;
          }
          .tooltip-bubble {
            right: -1px;
            bottom: 140%;
          }
          .network-type {
            position: relative;
            flex: 0 1 47%;
            cursor: pointer;
            &-wrapper {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 30px 10px 27px;
              border: 2px dashed #bac2d6;
              border-radius: 5px;
              cursor: pointer;
              .radio {
                display: none;
              }
              .standard-label {
                color: $cadet-grey;
                transition: color 0.2s ease-in-out;
              }
              &::after {
                content: "\F5E1";
                display: inline-block;
                font-family: "Material Design Icons";
                color: $cadet-grey;
                font-size: 20px;
                position: absolute;
                right: 11px;
                top: 5px;
                opacity: 0;
                transition: opacity 0.2s ease-in;
              }
              &:hover {
                .standard-label {
                  color: $black-coral;
                }
              }
              &.selected {
                border-style: solid;
                svg {
                  path {
                    fill: $black-coral;
                  }
                }
                .standard-label {
                  color: $black-coral;
                }
                &::after {
                  opacity: 1;
                }
              }
            }
            &.disabled {
              .network-type-wrapper {
                // opacity: 0.5;
                border-color: #d1d9f0;
                cursor: not-allowed;
                .standard-label {
                  color: $lavander-grey;
                }
              }
              &:hover {
                .standard-label {
                  color: $lavander-grey;
                }
              }
            }
          }
        }
      }
      .actions {
        margin: 40px 0px 0;
      }
    }
  }
  .info {
    padding-top: 24px;
    font-size: $fs-base;
    color: $black-coral;
    div {
      i {
        color: $cadet-grey;
        font-size: 3.25rem;
      }
    }
    &__text {
      line-height: 1.75rem;
      a {
        color: $red;
      }
    }
  }
}
