.box {
    width: 100%;
    display: block;

    &--full {
        width: 100%;
        height: 100%;

        &-w {
            width: 100%;
        }

        &-h {
            height: 100%;
        }
    }

    &--flex {
        display: flex;

        &--column {
            flex-flow: column;
            display: flex;

            &--wrap {
                flex-flow: column wrap;
                display: flex;
            }
        }

        &--row {
            flex-flow: row;
            display: flex;

            &--wrap {
                flex-flow: row wrap;
                display: flex;
            }
        }

        &--inline {
            display: inline-flex;
            width: auto;
        }
    }

    &--inline {
        display: inline;
    }
}

.flex {
    &--auto {
        flex: 1 1 auto;
    }

    &--scale {
        flex: 1 0 auto;
    }
}

.center {
    &--text {
        text-align: center;
    }

    &--margin {
        margin: auto;
    }

    &--self {
        &-start {
            align-self: flex-start;
        }

        &-end {
            align-self: flex-end;
        }

        &-center {
            align-self: center;
        }

        &-stretch {
            align-self: stretch;
        }
    }

    &--y {
        align-items: center;
    }

    &--Yyy {
        align-items: flex-start;
    }

    &--yyY {
        align-items: flex-end;
    }

    &--x {
        justify-content: center;
    }

    &--Xxx {
        justify-content: flex-start;
    }

    &--xxX {
        justify-content: flex-end;
    }

    &--XxX {
        justify-content: space-between;
    }

    &--xXx {
        justify-content: space-around;
    }

    &--m {
        margin: auto;
    }

    &--xy {
        justify-content: center;
        align-items: center;
    }
}

.overflow {
    &--h {
        overflow: hidden;

        &--x {
            overflow-x: hidden;
        }

        &--y {
            overflow-y: hidden;
        }
    }

    &--v {
        overflow: visible;

        &--x {
            overflow-x: visible;
        }

        &--y {
            overflow-y: visible;
        }
    }
}