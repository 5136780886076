@import "../../../assets/scss/variables.scss";

.sign-terms {
  background-color: $ghost-white;
  min-height: calc(100vh - 120px);
  form {
    .legal-entity-more {
      margin-bottom: 20px;
      background-color: $ghost-white;
      padding: 18px;
      text-align: left;
      color: $black-coral;
      font-size: $fs-sm;
      border-radius: 5px;
    }
    .form-check {
      margin-top: 15px;
    }
    .form-check-label {
      font-size: $fs-sm !important;
      &::before {
        border: 2px solid $lavander-grey !important;
        top: -2px !important;
      }
    }
    .form-check-input {
      &:checked + .form-check-label::before {
        border-top: 2px solid transparent !important;
        border-left: 2px solid transparent !important;
        border-right-color: $green !important;
        border-bottom-color: $green !important;
        top: -2px !important;
        box-shadow: none;
      }
      &:focus + .form-check-label::before,
      &:checked + .form-check-label::before {
        box-shadow: none !important;
      }
    }
  }
  &-form {
    padding: 30px 30px 40px 30px;
    margin-bottom: 60px;
  }
  &-description {
    padding: 20px 30px 40px;
    font-size: $fs-base;
    color: $black-coral;
    div {
      i {
        color: $cadet-grey;
        font-size: 3.25rem;
      }
    }
    &__text {
      line-height: 1.75rem;
      a {
        color: $red;
        &:hover {
          text-decoration: underline !important;
        }
      }
    }
  }
  &-completed {
    min-height: 400px;
    box-shadow: 0 0 20px rgba(92, 98, 117, 0.1);
    padding: 30px;
    margin-bottom: 60px;
    color: $cadet-grey;
    .mdi {
      &:before {
        font-size: 3rem;
      }
    }
    .solo-link {
      color: $cadet-grey;
      transition: color 0.2s ease-in-out;
      &:hover {
        color: $red;
      }
    }
    .text-black-coral {
      color: $black-coral;
    }
  }
  .h-splitter {
    width: 100%;
    height: 1px;
    background-color: $ghost-white;
    margin: 20px 0 40px 0;
  }
}

.sign-terms-back {
  .back-btn {
    height: 60px;
    line-height: 60px;
    &:hover {
      .mdi-chevron-left {
        color: $red;
        font-size: 1.4rem;
      }
    }
    .mdi-chevron-left {
      color: $cadet-grey;
      font-size: 1.4rem;
    }
  }
}
