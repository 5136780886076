@import './assets/scss/variables.scss';
@import './../node_modules/typeface-rubik/index';
@import './../node_modules/@mdi/font/scss/materialdesignicons.scss';
@import './industria-ui/scss/main.scss';
@import './assets/scss/box-model.scss';
@import './assets/scss/corda-network.scss';

.pt-60 {
    padding-top: 60px;
}

.pb-60 {
    padding-bottom: 60px;
}

.mb-60 {
    margin-bottom: 60px;
}

.px-15 {
    padding-left: 15px;
    padding-right: 15px;
}

.hidden {
    display: none !important;
}

.grecaptcha-badge {
    bottom: 65px !important;
}

.form-control {
    font-size: $p-sm-fs !important;
}

.color-red {
    color: $red;
}

.width-auto {
    width: auto;
}

.Modal {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: $white;
    z-index: 5;
    &__feedback,
    &__delete-account,
    &__terms-signed,
    &__admin-billing-details,
    &__admin-tou,
    &__approve-user,
    &__sponsored-participants,
    &__node-config {
        outline: none;
        .h3 {
            margin-bottom: 0;
        }
        .h-splitter {
            width: 100%;
            height: 1px;
            background: $ghost-white;
        }
        .mdi-close {
            position: absolute;
            top: 5px;
            right: 10px;
            color: $cadet-grey;
            font-size: 1.75rem;
            &:hover {
                cursor: pointer;
            }
        }
    }
    &__feedback {
        width: 580px;
        height: 500px;
        padding: 35px 30px 40px;
        top: calc(50% - 250px);
        left: calc(50% - 215px);
        box-shadow: none;
        p {
            margin-bottom: 0;
        }
        .h-splitter {
            margin: 20px 0 30px;
        }
        form {
            .form-group {
                .form-control.message-control {
                    padding: 1rem;
                    min-height: unset !important;
                    line-height: 1.2rem;
                }
                .message-control + span {
                    font-size: 0.875rem;
                    top: -58px !important;
                }
                .message-control.has-value + span {
                    font-size: 0.775rem;
                    top: -83px !important;
                }
                .message-control:focus + span {
                    font-size: 0.775rem;
                    top: -83px !important;
                }
            }
        }
        .actions {
            margin-top: 30px;
        }
        &--tou {
            height: 310px;
            top: calc(50% - 155px);
        }
    }
    &__delete-account {
        width: 580px;
        height: 280px;
        padding: 40px 30px;
        top: calc(50% - 140px);
        left: calc(50% - 215px);
        position: relative;
        .h-splitter {
            margin: 30px 0 30px;
        }
        p {
            padding: 0 50px;
            text-align: center;
            a {
                color: $red;
            }
        }
        .close-btn {
            text-transform: uppercase;
            color: $cadet-grey;
            font-size: 0.875rem;
            text-align: center;
            margin-top: 30px;
            font-weight: $fw-bold;
            &:hover {
                cursor: pointer;
            }
        }
    }
    &__terms-signed {
        width: 580px;
        height: 420px;
        padding: 35px 30px 40px;
        top: calc(50% - 210px);
        left: calc(50% - 215px);
        .h3 {
            padding: 0 90px;
        }
        .h-splitter {
            margin: 30px 0 30px;
        }
        p {
            margin-bottom: 35px;
            a {
                color: $red;
            }
        }
        .close-btn {
            text-transform: uppercase;
            color: $cadet-grey;
            font-size: 0.875rem;
            text-align: center;
            margin-top: 30px;
            font-weight: $fw-bold;
            &:hover {
                cursor: pointer;
            }
        }
    }
    &__admin-billing-details,
    &__admin-tou {
        width: 580px;
        height: 570px;
        padding: 35px 30px 40px;
        top: calc(50% - 285px);
        left: calc(50% - 215px);
        .h3 {
            margin-bottom: 30px;
        }
        .no-bd,
        .no-terms {
            .mdi-file-document-box-remove-outline {
                width: 40px;
                &:before {
                    color: $cadet-grey;
                    font-size: 2.5rem;
                }
            }
            p {
                font-size: $fs-lg;
                color: $cadet-grey;
            }
        }
        .no-bd {
            min-height: 430px;
        }
        .no-terms {
            min-height: 240px;
        }
        .confirm-table {
            padding-bottom: 20px;
            &-row {
                border-radius: 5px;
                padding: 18px 20px;
                font-size: $fs-sm;
                .value {
                    font-weight: 500;
                }
                .name,
                .value {
                    flex: 1 0 49%;
                    line-height: 1.5rem;
                }
                &:nth-child(odd) {
                    background: $ghost-white;
                }
            }
        }
    }
    &__admin-tou {
        width: 580px;
        height: 450px;
        padding: 35px 30px 40px;
        top: calc(50% - 225px);
        left: calc(50% - 215px);
        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 6px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $ghost-white;
            border-radius: 5px;
            height: 50px;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $black-coral;
        }
        .no-bd {
            min-height: 200px;
            font-size: 1.5rem;
        }
    }
    &__approve-user {
        width: 580px;
        height: 380px;
        padding: 35px 30px 40px;
        top: calc(50% - 190px);
        left: calc(50% - 215px);
        p {
            margin-top: 32px;
            text-align: center;
        }
    }
    &__admin-billing-details {
        width: 600px;
        height: 570px;
        padding: 35px 30px 0 30px;
        top: calc(50% - 285px);
        left: calc(50% - 225px);
        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 6px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $ghost-white;
            border-radius: 5px;
            height: 50px;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $black-coral;
        }
    }
    &__sponsored-participants {
        width: calc(100vw - 250px);
        height: calc(100vh - 200px);
        top: 100px;
        left: 200px;
        overflow-x: overlay;
        &::-webkit-scrollbar {
            width: 6px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $ghost-white;
            border-radius: 5px;
            height: 50px;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $black-coral;
        }
    }
    &__node-config {
        position: relative;
        width: 50%;
        height: auto;
        left: 75px;
        top: 50%;
        transform: translateY(-50%);
        padding: 40px 30px;
        margin: 0 auto;
        color: $black-coral;
        .value {
            font-weight: $fw-bold;
            margin-left: 20px;
        }
        .mdi-close {
            top: 10px;
            right: 25px;
            transition: color 0.2s ease-in-out;
            &:hover {
                color: $red;
            }
        }
        .h-splitter {
            margin: 18px 0;
        }
        h1.h3 {
            margin-top: 10px;
            margin-bottom: 25px;
        }
        .btn-download {
            margin-top: 30px;
            margin-bottom: 0;
            .size {
                font-weight: $fw-normal;
                color: $cadet-grey;
                text-transform: lowercase;
            }
        }
    }
    &__customer-agreement {
        position: relative;
        height: calc(100vh - 120px);
        overflow-x: overlay;
        top: 50%;
        left: calc(50% + 75px);
        transform: translate(-50%, -50%);
        width: 60%;
        outline: 0;
        margin-bottom: 60px;
        i {
            position: absolute;
            top: 12px;
            right: 22px;
            color: $cadet-grey;
            font-size: 1.75rem;
            transition: color 0.2s ease-in-out;
            &:hover {
                cursor: pointer;
                color: $red;
            }
        }
        ol {
            margin-bottom: 0;
            padding: 66px 50px 24px 60px;
            list-style-type: decimal;
            list-style-position: inside;
            li {
                padding: 0;
                margin-bottom: 1rem;
                text-indent: 0;
                font-weight: $fw-normal;
                color: $black-coral;
                &:before,
                &:after {
                    content: none;
                }
            }
        }
        .close-btn {
            text-align: center;
            padding: 24px 0;
            margin: 0 50px 0 60px;
            border-top: 1px solid $ghost-white;
        }
        &::-webkit-scrollbar {
            width: 6px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $ghost-white;
            border-radius: 5px;
            height: 50px;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $black-coral;
        }
    }
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
    &__feedback {
        background-color: rgba(49, 56, 71, 0.9);
        opacity: 1;
        z-index: 22;
    }
}

.Toastify {
    &__toast {
        border-radius: 5px !important;
        text-align: center;
        &--success {
            background-color: $green !important;
        }
        &--error {
            background-color: $red !important;
        }
    }
}

.paginator {
    padding: 30px 0;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    li {
        padding-left: 0;
        width: 35px;
        background-color: transparent;
        height: 35px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
        transition: background-color 0.2s ease-in-out;
        &:before {
            content: none;
        }
        a {
            color: $cadet-grey;
            font-size: 0.875rem;
        }
        &.active {
            background-color: $ghost-white;
            a {
                color: $black-coral;
            }
        }
        &.disabled a {
            color: $ghost-white;
            cursor: default;
        }
    }
}

.create-node-join-network,
.create-node-config {
    .back {
        display: inline-block;
        margin-bottom: 20px;
        .btn {
            margin-top: 0;
            margin-bottom: 0;
        }
        i {
            font-size: 1.25rem;
            color: $cadet-grey;
            vertical-align: middle;
            margin-right: 3px;
            transition: color 0.2s ease-in-out;
        }
        &:hover {
            cursor: pointer;
            i,
            .btn {
                color: $red;
            }
        }
    }
}

.gsc-adBlock {
    display: none !important;
}

#adBlock {
    display: none !important;
}