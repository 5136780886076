@import "./../../assets/scss/variables.scss";

.production-terms {
  min-height: calc(100vh - 120px);
  margin-top: -40px;
  .back-btn {
    height: 60px;
    line-height: 60px;
    &:hover {
      .mdi-chevron-left {
        color: $red;
        font-size: 1.4rem;
      }
    }
    .mdi-chevron-left {
      color: $cadet-grey;
      font-size: 1.4rem;
    }
  }
  &__container {
    padding: 40px 30px;
    margin-bottom: 60px;
    .h3 {
      font-weight: 500;
    }
    p {
      font-size: 0.875rem;
    }
    .h-splitter {
      width: 100%;
      height: 1px;
      background: $ghost-white;
      margin: 30px 0;
    }
    .btn-secondary {
      margin-left: 30px !important;
    }
    .terms {
      border: 1px solid $ghost-white;
      padding: 24px 4px 24px 24px;
      margin-top: 10px;
      margin-bottom: 30px;
      border-radius: 5px;
      &__wrapper {
        overflow-y: scroll;
        max-height: 600px;
        font-size: 0.8125rem;
        &::-webkit-scrollbar {
          width: 6px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
          background: transparent;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $ghost-white;
          border-radius: 5px;
          height: 50px;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $black-coral;
        }
        p {
          padding-right: 20px;
          margin-bottom: 25px;
          font-size: 0.8125rem;
          line-height: 23px;
        }
        h3 {
          font-family: $ff-base;
          font-weight: $fw-bold;
          color: $onyx;
          &:first-of-type {
            text-align: center;
          }
        }
        a {
          color: $red !important;
        }
      }
    }
    form {
      .form-check {
        margin-top: 15px;
      }
      .form-check-label {
        font-size: $fs-sm !important;
        &::before {
          border: 2px solid $lavander-grey !important;
          top: -2px !important;
        }
      }
      .form-check-input {
        &:checked + .form-check-label::before {
          border-top: 2px solid transparent !important;
          border-left: 2px solid transparent !important;
          top: -2px !important;
        }
        &:focus + .form-check-label::before,
        &:checked + .form-check-label::before {
          box-shadow: none !important;
        }
      }
    }
    .actions {
      .btn-next,
      .btn-ask {
        padding: 17px 30px;
      }
    }
  }
  &-description {
    margin-top: 20px;
    padding: 0px 30px 40px;
    font-size: $fs-base;
    color: $black-coral;
    div {
      i {
        color: $cadet-grey;
        font-size: 3.25rem;
      }
    }
    &__text {
      line-height: 1.75rem;
      a {
        color: $red;
        &:hover {
          text-decoration: underline !important;
        }
      }
    }
  }
  .btn-link {
    color: $cadet-grey;
    background-image: none;
    &:hover {
      cursor: pointer;
      color: $red;
    }
  }
}
