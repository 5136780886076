.tabs-slider {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  @include respond-below(sm) {
    display: block;
  }
  .section-tabs-label {
    .zmdi {
      font-size: 26px;
      color: $asphalt;
      margin-left: 20px;
    }
    &::before {
      background: $mist;
    }
  }
  .tabs-nav {
    position: relative;
    width: 322px;
    height: 50px;
    border: 2px solid $air;
    border-radius: 25px;
    background: transparent;
    padding: 0;
    list-style-type: none;
    margin-left: 20px;
    @include respond-below(sm) {
      border: none;
      margin: 30px 0 0 0;
      display: block;
    }
  }
  .tabs {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
    position: relative;
    .selected-tab {
      width: 34%;
      height: 50px;
      position: absolute;
      top: -2px;
      border-radius: 25px;
      border: 2px solid $ocean;
      background-color: $ocean;
      box-shadow: 0 2px 10px 0 rgba(56, 140, 196, 0.4);
      margin-bottom: 0;
      transition: all 300ms ease-in-out;
      z-index: 1;
      left: -2px;
      &::before {
        display: none;
      }
      @include respond-below(sm) {
        width: 95px;
        height: 36px;
        left: 1px !important;
        top: 1px;
        box-shadow: none !important;
      }
    }
    .tab {
      margin: 0;
      padding: 0;
      width: 33.333%;
      text-transform: uppercase;
      font-weight: $fw-bold;
      color: $ocean;
      text-align: center;
      padding: 10px 0;
      position: relative;
      z-index: 2;
      width: 33.333%;
      &:hover {
        cursor: pointer;
      }
      &:before {
        display: none;
      }
      &.selected {
        color: $white;
        transition: all 300ms ease-in-out;
        @include respond-below(sm) {
          background-color: $night;
        }
      }
      @include respond-below(sm) {
        border-radius: 25px;
        background-color: $air;
        width: 149px;
        padding: 6px 21px;
        margin-right: 10px;
      }
    }
  }
}
