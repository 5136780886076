@import "../../../../assets/scss/variables.scss";

.confirm-legal-entity {
  margin-bottom: 30px;
  .confirm {
    &-wrapper {
      padding: 36px 30px 40px;
      .h-splitter {
        width: 100%;
        height: 1px;
        background-color: $ghost-white;
        margin: 20px 0 40px 0;
      }
      h1 {
        font-weight: $fw-bold;
      }
      .h4.company-name {
        font-weight: $fw-bold;
        font-size: $fs-lg;
        margin-top: -20px;
        margin-bottom: 20px;
        &::before {
          content: "\FA36";
          display: inline-block;
          font-family: "Material Design Icons";
          font-size: 1.4375rem;
          color: $cadet-grey;
          font-weight: $fw-normal;
          margin-right: 15px;
          margin-top: -20px;
          margin-bottom: 13px;
        }
      }
      .btn-back,
      .btn-next {
        margin: 0;
      }
    }
    &-table {
      &-row {
        border-radius: 5px;
        padding: 20px;
        color: #5c6275;
        font-size: 14px;
        .value {
          font-weight: 500;
          line-height: 1.5rem;
        }
        .name,
        .value {
          flex: 0 1 50%;
        }
      }
      &-row.odd {
        background-color: #edf0f7;
      }
      &-row.even {
        background-color: transparent;
      }
    }
    &-explanation {
      padding: 18px 32px 18px 18px;
      background-color: $ghost-white;
      border-radius: 5px;
      font-size: 0.875rem;
      color: $black-coral;
      margin: 0 0 40px;
      line-height: 1.625rem;
      a {
        color: $red;
        background-image: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
