@import "./../../assets/scss/variables.scss";

.contact-button {
    position: relative;
    margin: 0 0 0 auto;
    opacity: .75;
    font-size: .875rem;
    font-weight: 700;
    padding: 14px 33px;
    display: flex;
    align-items: center;
    border: 1px solid $red;
    text-decoration: none;
    background-color: $red;
    color: $white; 
    border-radius: 30px;
    line-height: 23px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 16%);
    min-width: 153px;
    text-align: center;
    outline: none; 
    cursor: pointer;

    &::before {
        content: "\F1F0";
        display: inline-block;
        font-family: "Material Design Icons";
        font-size: 25px;
        color: $white;
        margin-right: 10px;
      }
      
    &-container {
        position: fixed;
        bottom: 7%;
        right: 2rem;
        z-index: 20;
        max-width: 500px;
        width: auto;
    }
}