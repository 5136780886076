@import "./../../assets/scss/variables.scss";

.participants {
  h1 {
    padding-top: 30px;
  }
  h3 {
    color: $cadet-grey;
    font-size: $fs-lg;
    font-weight: $fw-normal;
    margin-bottom: 35px;
  }
  h1,
  h3 {
    padding-left: 30px;
  }
  &__header {
    height: 60px;
    background-color: $ghost-white;
    margin-right: 0px;
    margin-left: 0px;
    padding: 25px 30px 40px 30px;
    &--item {
      font-size: 0.875rem;
      text-transform: uppercase;
      font-weight: 500;
      color: $cadet-grey;
      padding: 0;
    }
  }
  &__body {
    &--participant {
      margin: 0;
      padding: 20px 30px;
      border-bottom: 1px solid $ghost-white;
      [class*="col-"] {
        padding: 0;
      }
      .externalId {
        color: $cadet-grey;
        font-size: 0.875rem;
        font-weight: $fw-bold;
        line-height: 26px;
      }
      .name,
      .country,
      .confirmed,
      .address {
        color: $black-coral;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 26px;
      }
      .confirmed,
      .address {
        font-weight: $fw-normal;
      }
      i.mdi-account-check-outline {
        width: 50px;
        height: 50px;
        display: block;
        background-color: $ghost-white;
        border-radius: 5px;
        &:hover {
          cursor: pointer;
        }
        &:before {
          color: $black-coral;
          position: absolute;
          top: 8px;
          font-size: 1.55rem;
          left: 13px;
        }
      }
    }
    .closer {
      border-top: 1px solid $ghost-white;
      color: $cadet-grey;
      text-transform: uppercase;
      font-weight: 500;
      z-index: 999;
      width: 100%;
      height: 90px;
      margin-left: 0px;
      span {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
