@import "./../../assets/scss/variables.scss";
@import "./../../industria-ui/scss/mixins/media-queries";

.privacy-popup {
  display: block;
  background-color: rgba(83, 88, 95, .98);
  border-radius: 3px;
  color: $white;
  font-size: 0.8125rem;
  left: calc(#{$closed-navigation-width} + 3px);
  position: fixed;
  padding: 6px;
  right: 3px;
  text-align: center;
  z-index: 999;
  bottom: 5px;
  top: inherit;

  @include respond-below(md) {
    left: 3px;
  }

  h3, p {
    color: inherit;
    letter-spacing: 0;
  }

  p {
    font-size: 1.125rem;
    margin-bottom: 10px;
    padding: 8px 36px 0;
    line-height: 1.4rem;
    a {
      color: #62aaf1;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  h3 {
    font-size: 1.5rem;
    font-weight: $fw-normal;
    margin: 9px 0 0;
    text-transform: uppercase;
  }

  .cookie-consent-form {

    fieldset {
      min-width: 0;
      padding: 0;
      margin: 0;
      border: 0;
    }

    legend {
      font-size: 1.15rem;
      margin-bottom: 16px;
      padding: 8px 36px 0;
    }

    .accept-btn {
      cursor: pointer;
      background-color: $red;
      border: none;
      border-radius: 6px;
      color: inherit;
      font-size: 0.875rem;
      font-weight: $fw-bolder;
      margin: 0 10px 10px;
      max-height: 40px;
      height: 40px;
      line-height: 1.1rem;
      text-decoration: none;
      width: $closed-navigation-width;

      &-group {
        display: flex;
        justify-content: center;
      }

      &:focus {
        outline: none;
      }
    }

    .cookies-checkbox {
      display: inline-block;
      margin: 5px 10px;

      input[type="checkbox"] {
        box-sizing: border-box;
        padding: 0;
      }

      label {
        font-size: 1rem;
        margin-left: 3px;
        display: inline-block;
        margin-bottom: .5rem;
      }
    }
  }
}
