@import "../../../../assets/scss/variables.scss";

.confirm-billing-details {
  .card {
    &-body {
      padding: 36px 30px 40px;
      h1.h3 {
        font-weight: $fw-bold;
      }
      .h-splitter {
        width: 100%;
        height: 1px;
        background: $ghost-white;
        margin: 20px 0 40px 0;
      }
      .confirm-table {
        padding-bottom: 20px;
        &-row {
          border-radius: 5px;
          padding: 18px 20px;
          font-size: $fs-sm;
          .value {
            font-weight: 500;
          }
          .name,
          .value {
            flex: 0 1 49%;
            line-height: 1.5rem;
          }
          &:nth-child(odd) {
            background: $ghost-white;
          }
        }
      }
    }
  }
}
