.btn {
  font-family: $ff-base;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: $fw-bold;
  text-transform: uppercase;
  border-radius: 1.4375rem;
  padding: 0.8125rem 1.4375rem;
  line-height: 1;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  border: 2px solid transparent;
  outline: 0;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  & > .zmdi {
    margin-left: 0.625rem !important;
    font-size: 1rem;
  }
  &:hover {
    text-decoration: none;
    background-image: none !important;
  }
  &[disabled="disabled"],
  &:disabled,
  &.disabled {
    cursor: not-allowed;
    background: transparent !important;
    box-shadow: unset !important;
    opacity: 0.7;
  }
}

.btn-lg {
  font-size: 1rem;
  padding: 1.125rem 1.75rem;
  border-radius: 1.75rem;
}

.btn-circle {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.375rem 0px;
  border-radius: 50%;
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.42857;
  border: 0;
  background-image: unset !important;
  .zmdi {
    margin-left: 0 !important;
    font-size: 1.25rem !important;
    line-height: 1.5 !important;
  }
}

.btn-circle--lg {
  width: 3.125rem;
  height: 3.125rem;
  padding: 0.625rem 0px;
  border-radius: 50%;
  text-align: center;
  font-size: 1.375rem;
  line-height: 1.42857;
  border: 0;
  & > .zmdi {
    margin-left: 0 !important;
    font-size: 1.375rem !important;
    line-height: 1.5 !important;
  }
}

.btn-primary {
  background: $ocean;
  color: $white;
  border-color: $ocean;
  &:focus {
    box-shadow: 0 0 0.625rem 0 rgba(64, 159, 222, 0.9);
  }
  &:hover {
    background: #409fde;
    border-color: #409fde;
    box-shadow: 0 0.125rem 0.625rem 0 rgba(56, 140, 196, 0.4);
  }
  &:active {
    box-shadow: 0 0.0625rem 0.25rem 0 rgba(56, 140, 196, 0.6);
  }
  &[disabled="disabled"],
  &:disabled,
  &.disabled {
    color: $mist;
    border-color: $mist;
  }
  &.btn-circle,
  &.btn-circle--lg {
    background: $ocean;
    border: 0;
    &:hover {
      color: $white;
      background: #409fde;
    }
  }
}

.btn-secondary {
  color: $ocean;
  background: $air;
  border-color: $air;
  &:focus {
    box-shadow: 0 0 0.625rem 0 rgba(121, 166, 196, 0.55);
  }
  &:hover {
    box-shadow: 0 0.125rem 0.625rem 0 rgba(121, 166, 196, 0.4);
  }
  &:active {
    box-shadow: 0 0.0625rem 0.25rem 0 rgba(121, 166, 196, 0.45);
  }
  &[disabled="disabled"],
  &:disabled,
  &.disabled {
    color: $mist;
    border-color: $mist;
  }
  &.btn-circle,
  &.btn-circle--lg {
    background: $air;
    border: 0;
    &:hover {
      color: $ocean;
    }
  }
}

.btn-circle:hover {
  color: #409fde;
}

.btn-circle.mist:hover {
  color: #8daec5 !important;
}

.btn-download {
  text-transform: uppercase;
  background: transparent;
  color: $ocean;
  border-color: $air;
  .file-size {
    font-weight: $fw-normal;
    color: $asphalt;
    margin-left: 1.25rem;
  }
  .zmdi {
    vertical-align: middle;
    font-size: 1rem;
    margin-right: 0.625rem;
  }
  &:focus {
    box-shadow: 0 0 0.625rem 0 rgba(121, 166, 196, 0.25);
  }
  &:hover {
    background: $air;
    .file-size {
      color: $ocean;
    }
  }
  &:active {
    background: $air;
    box-shadow: inset 0 0.0625rem 0.1875rem 0 rgba(121, 166, 196, 0.2);
    .file-size {
      color: $ocean;
    }
  }
}
