@import "../../../assets/scss/variables.scss";

.terms-of-use {
  padding: 40px 30px;
  .h-splitter {
    width: 100%;
    height: 1px;
    background-color: $ghost-white;
    margin: 20px 0 40px 0;
  }
  h1 {
    font-weight: $fw-bold;
  }
  .production,
  .pre-production {
    position: relative;
    border: 2px dashed $lavander-grey;
    padding: 30px 30px 30px 20px;
    margin-bottom: 30px;
    border-radius: 5px;
    color: $cadet-grey;
    &__selected {
      border: 2px solid $lavander-grey;
      color: $black-coral;
      i {
        display: initial !important;
        font-size: 1.75rem;
        line-height: 28px;
        position: absolute;
        top: 10px;
        right: 10px;
        color: $cadet-grey;
      }
    }
    &__header {
      font-weight: $fw-bold;
      font-size: $fs-sm;
      text-transform: uppercase;
      margin-bottom: 15px;
      line-height: 14px;
      span {
        font-weight: normal;
      }
    }
    &__info {
      font-size: 0.8125rem;
      line-height: 20px;
    }
    i {
      display: none;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .pre-production {
    margin-bottom: 20px;
  }
  .signer-form-description {
    font-size: 0.875rem;
  }
  form {
    .legal-entity-more {
      margin-bottom: 20px;
      background-color: $ghost-white;
      padding: 18px;
      text-align: left;
      color: $black-coral;
      font-size: $fs-sm;
      border-radius: 5px;
    }
    .form-check {
      margin-top: 15px;
    }
    .form-check-label {
      font-size: $fs-sm !important;
      &::before {
        border: 2px solid $lavander-grey !important;
        top: -2px !important;
      }
    }
    .form-check-input {
      &:checked + .form-check-label::before {
        border-top: 2px solid transparent !important;
        border-left: 2px solid transparent !important;
        border-right-color: $green !important;
        border-bottom-color: $green !important;
        top: -2px !important;
        box-shadow: none;
      }
      &:focus + .form-check-label::before,
      &:checked + .form-check-label::before {
        box-shadow: none !important;
      }
    }
  }
  .awaiting-signer {
    color: $cadet-grey;
    p {
      color: $black-coral !important;
    }
    form {
      margin-bottom: 20px;
      .form-group {
        width: 75%;
        margin: 0 auto;
      }
    }
    .subtitle {
      color: $cadet-grey !important;
      font-size: 20px;
    }
    i {
      margin-bottom: 10px;
      &:before {
        font-size: 2.5rem;
        line-height: 2.5rem;
        content: "\FB33" !important;
      }
    }
    p {
      color: $black-coral;
    }
    form {
      width: 80%;
      max-width: 400px;
      margin: 0 auto;
    }
    .solo-link {
      font-size: 0.875rem;
      color: $cadet-grey;
      transition: color 0.2s ease-in-out;
      &:hover {
        color: $red;
      }
    }
  }
}
