@import "./../../assets/scss/variables.scss";
@import "./../../industria-ui/scss/mixins/media-queries";

.navigation {
  width: $closed-navigation-width;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  padding-right: 0;
  background: rgba(49, 56, 71, 0.85);
  transition: width 0.3s ease-out, padding-right 0.3s ease-out, background 0.3s ease-out 0.3s;
  @include respond-below(md) {
    height: 0;
  }
  &-wrapper {
    background: $white;
    box-shadow: 0 0 20px 0 rgba(92, 98, 117, 0.1);
    width: 100%;
    position: relative;
    overflow: hidden;
    .navbar {
      padding-top: 30px;
      text-align: center;
      width: $closed-navigation-width;
      height: 100%;
      position: relative;
      @include respond-below(md) {
        padding-top: 0;
        text-align: left;
        width: 100%;
        height: 65px;
        position: fixed;
        background: $white;
        display: flex;
        align-items: center;
        box-shadow: 0 3px 20px 0 rgba(92, 98, 117, 0.1);
      }
      .logo {
        background-image: none;
        @include respond-below(md) {
          max-width: 110px;
          padding-left: 15px;
          padding-top: 6px;
          padding-bottom: 5px;
          font-size: 0.625rem;
          line-height: 1.75;
        }
        color: $black-coral !important;
        text-transform: uppercase !important;
        font-size: 0.875rem;
        line-height: 1.5rem;
        font-weight: $fw-bold;
        text-align: left;
        padding-left: 25px;
        padding-right: 10px;
        transition: color 0.2s ease-in-out;
        &:hover {
          color: $onyx-dark !important;
        }
      }
      .hamburger-menu {
        cursor: pointer;
        width: 30px;
        height: 30px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include respond-below(md) {
          left: inherit;
          right: 0;
        }
        .line {
          width: 30px;
          height: 3px;
          pointer-events: none;
          background-color: $black-coral;
          display: block;
          margin: 5px auto;
          transition: all 0.3s ease-in-out;
          @include respond-below(md) {
            width: 25px;
          }
        }
      }
    }
    .inner-nav {
      position: absolute;
      left: $closed-navigation-width;
      right: 0;
      top: 0;
      @include respond-below(md) {
        position: fixed;
        left: 0;
        right: 0;
        height: 0;
        visibility: hidden;
        top: 65px;
        transition: height 0.2s ease;
        height: 100vh;
      }
      .container-fluid {
        height: inherit;
      }
      .searchfield {
        position: relative;
        height: 100px;
        background-color: $ghost-white;
        & * {
          background-color: $ghost-white;
        }
        @include respond-below(md) {
          height: 70px;
        }
        .search-form {
          display: flex;
          align-items: center;
          .form-group {
            margin-bottom: 0;
            display: flex;
            align-items: center;
            .form-control {
              border: 0;
              margin-top: 0;
              caret-color: $black-coral;
              color: $black-coral;
              padding: 0.6875rem 0;
              line-height: 1rem !important;
              &::placeholder {
                color: $cadet-grey;
                text-transform: uppercase;
                font-size: $fs-sm;
              }
              &:focus {
                &::placeholder {
                  visibility: hidden;
                }
              }
            }
            .btn-search {
              position: absolute;
              right: -7px;
              margin: 0;
              padding: 0;
              background: transparent;
              display: none;
              &:after {
                content: "\F349";
                display: inline-block;
                font-family: "Material Design Icons";
                font-size: 2.1875rem;
                font-weight: $fw-normal;
                color: $cadet-grey;
                transition: color 0.2s ease-in-out;
                @include respond-below(md) {
                  font-size: 2rem;
                }
              }
              &:hover {
                &::after {
                  color: $red;
                }
              }
            }
          }
        }
      }
      .nav-main {
        height: calc(100% - 100px);

        ul {
          margin-bottom: 0;
          
          li {
            padding-left: 0;
            font-size: 0.875rem;
            margin-bottom: 14px;

            &::before {
              display: none;
            }

            a {
              background-image: none;
              text-transform: uppercase;
              padding-bottom: 0;
            }
          }
        }
        .menu-not-authenticated {
          height: 90px;
          width: 100%;
          border-bottom: 1px solid $ghost-white;
          opacity: 0;

          @include respond-below(md) {
            height: 60px;
            margin-bottom: 0;
          }

          ul .nav-item {
            margin-bottom: 0;
            font-size: $fs-sm;

            .nav-link {
              color: $cadet-grey;
              transition: color 0.2s ease-in-out;
            }

            &:hover {
              .nav-link {
                color: $red;
              }
            }
          }
        }
        .menu {
          display: flex;
          flex-direction: column;
          justify-content: center;
          min-height: calc(100vh - 210px);
          margin-top: 20px;
          margin-bottom: 20px;
          margin-right: 20px;

          @include respond-below(md) {
            margin: 25px 0;
          }

          &.user-authorized {
            min-height: calc(100vh - 230px);
            margin: 30px 0;
          }
        }
        .main-menu {
          position: relative;

          @include respond-below(md) {
            height: 100%;
          }
          
          .onboarding-item {
            display: inline-block;
            padding-top: 4px;
            white-space: initial;
            cursor: default;

            @include respond-below(md) {
              display: block;
            }

            .in-progress-text {
              max-width: 230px;
              font-size: 0.875rem;
              line-height: 1.75rem;
              font-weight: $fw-normal;
              text-transform: none;
              color: $cadet-grey;
              display: flex;
              flex-direction: column;

              span {
                margin-bottom: 20px;
              }
            }

            .nav-link {
              margin-top: 20px;
              display: block !important;
              width: max-content !important;
              line-height: 1.1 !important;
              font-weight: $fw-bold !important;
              padding: 0.875rem 1.75rem !important;
              background: $ghost-white !important;
              color: $black-coral !important;
              border-radius: 0.3125rem !important;
            }
          }

          .nested-menu {
            @include respond-below(md) {
              width: fit-content;
            }

            &:first-of-type {
              width: max-content;
              position: relative;

              li {
                &:first-of-type {
                  padding-top: 0;
                }
              }

              .submenu-link {
                padding: 10px 0 10px 0;
              }
            }

            .nav-link, .submenu-link {
              margin-bottom: 0;
              position: static;
              text-transform: uppercase;
              color: #313847;
              padding: 5px 10px 5px 0;
            }

            .nav-link {
              padding: 4px 10px 4px 0;
              font-weight: $fw-normal;
              color: $black-coral;
              font-size: 0.875rem;
  
              &.active {
                color: $red;
              }
            }

            .submenu-link {
              position: initial;

              .submenu-link-text {
                cursor: pointer;

                &::after {
                  content: "\F142";
                  position: absolute;
                  font-family: "Material Design Icons";
                  font-size: 1.4375rem;
                  vertical-align: bottom;
                  padding-left: 0;
                  font-weight: normal;
                  color: $onyx;
                  cursor: pointer;
                  opacity: 0;
                  transition: padding-left 0.2s ease, color 0.2s ease-in-out;

                  @include respond-below(md) {
                    content: "\F140";
                  }
                }
              }

              &.active, &:hover, .nav-link:hover {
                color: $red;
                transition: color .2s ease-in-out;
              }

              &.active {
                > .submenu-link-text {
                  &::after {
                    opacity: 1;
                    color: inherit;
                    @include respond-above(md) {
                      padding-left: 4px;
                    }
                  }
                }
              }

              @include respond-below(md) {
                position: relative;

                &:not(:first-of-type) {
                  &::after {
                    top: 8px
                  }
                }
              }
              .nested-menu {
                position: absolute;
                left: 100%;
                top: 0;
                margin-top: 0;
                padding-left: 40px;
                display: none;
    
                @include respond-below(md) {
                  position: relative;
                  left: 0;

                  li {
                    padding-left: 20px;
                    &:first-of-type {
                      margin-top: 20px;
                    }
                  }
                } 
              }
              
              &.active {
                > .nested-menu {
                  display: initial;
                }
              }
            }
          }

          #account-link::before {
            content: "\F013";
            display: inline-block;
            font-family: "Material Design Icons";
            color: $red;
            font-size: 1.4375rem;
            margin-right: 5px;
            vertical-align: top;
          }

          #users-link::before {
            content: "\FB34";
            display: inline-block;
            font-family: "Material Design Icons";
            color: $red;
            font-size: 1.4375rem;
            margin-right: 5px;
            vertical-align: top;
          }
        }
        .social-networks {
          display: none;
          border-left: 1px solid $ghost-white;
          @include respond-below(md) {
            display: none;
          }
          ul {
            @include respond-below(md) {
              display: flex;
              width: 100%;
              justify-content: space-evenly;
              padding-top: 25px;
            }
            li {
              margin-bottom: 50px;
              i {
                font-size: 1.875rem;
                color: $lavander-grey;
                transition: color 0.2s ease-in-out;
                &:hover {
                  color: $red;
                }
              }
            }
          }
        }
      }
    }
  }
  &.open {
    width: 100%;
    padding-right: 35%;
    @include respond-below(md) {
      width: 100%;
      padding-right: 0;
    }
    .navbar {
      border-right: 1px solid $ghost-white;
      .logo {
        color: $black-coral !important;
        text-transform: uppercase !important;
        font-size: 0.875rem;
        line-height: 1.5rem;
        font-weight: $fw-bold;
        text-align: left;
        padding-left: 25px;
        padding-right: 10px;
        transition: color 0.2s ease-in-out;
        &:hover {
          color: $onyx-dark !important;
        }
        @include respond-below(md) {
          font-size: 0.625rem;
          line-height: 1.75;
        }
      }
      .hamburger-menu {
        .line.top,
        .line.bottom {
          width: 20px;
        }
        .line.top {
          transform: translateX(-6px) rotate(-45deg);
        }
        .line.bottom {
          transform: translateX(-6px) rotate(45deg);
        }
        @include respond-below(md) {
          .line.top,
          .line.bottom {
            width: 25px;
          }
          .line.top {
            position: absolute;
            transform: rotate(45deg);
            top: 6px;
          }

          .line.middle {
            width: 0%;
            opacity: 0;
          }

          .line.bottom {
            position: absolute;
            transform: rotate(-45deg);
            top: 6px;
          }
        }
      }
    }
    .inner-nav {
      height: 100%;
      visibility: visible;
      background: $white;
      overflow-y: auto;
      @include respond-below(md) {
        height: calc(100% - 65px);
      }
      .menu-not-authenticated {
        opacity: 1;
      }
    }
    .btn-search {
      display: block !important;
    }
  }
}
